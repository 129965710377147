import { PlantsCreateRequest, PlantsGetResponse } from '@app/api/plants';
import { handleSendEvent } from '@app/utils';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreateNewPlantModal from './modals/CreateNewPlantModal';
import PlantManagementTable from './PlantManagementTable';
import FormButton from '@app/components/shared/FormButton';
import { Box } from '@material-ui/core';
import { PlantStatus } from '@app/api/plants/PlantsGetResponse';
import useRenderFormFilterWithTags from '@app/hooks/useRenderFormFilterWithTags';
import { getStatusByItsTranslationKey, getStatusTranslationKey } from '@app/components/shared/StatusComponent';
import useLoadCustomers from '@app/hooks/useLoadCustomers';
import Loading from '@app/components/shared/Loading';
import FormCheckbox from '@app/components/shared/FormCheckbox';
import { FormSelectOptions } from '@app/components/shared/FormSelect';

interface PlantManagementListContentProps {
  plants: PlantsGetResponse[];
  createPlant: (plant: PlantsCreateRequest) => void;
  openCreateNewPlantModal: boolean;
  setOpenCreateNewPlantModal: Dispatch<SetStateAction<boolean>>;
  handleConfirmBlockModal: (plant: PlantsGetResponse) => void;
  renderBlockPlantDialog: () => void;
  filter: PlantManagementFormInputs;
}

export interface PlantManagementFormInputs {
  search: string;
  customers: string[];
  status: string[];
  expired: boolean;
}

export const plantStatusOptions: FormSelectOptions<string> = [
  {
    name: getStatusTranslationKey(PlantStatus.ACTIVE),
    value: PlantStatus.ACTIVE
  },
  {
    name: getStatusTranslationKey(PlantStatus.INACTIVE),
    value: PlantStatus.INACTIVE
  },
  {
    name: getStatusTranslationKey(PlantStatus.BLOCKED),
    value: PlantStatus.BLOCKED
  },
  {
    name: getStatusTranslationKey(PlantStatus.TRIAL),
    value: PlantStatus.TRIAL
  },
]

const PlantManagementContent: React.FC<PlantManagementListContentProps> = (
  props: PlantManagementListContentProps,
) => {
  const {
    plants,
    createPlant,
    openCreateNewPlantModal,
    setOpenCreateNewPlantModal,
    handleConfirmBlockModal,
    renderBlockPlantDialog,
    filter
  } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();

  const statusOptions = plantStatusOptions.map(item => item.name)

  const filteredPlants = useMemo(() => {
    const result = plants.filter(plant => {
      const textSearch = !filter.search || (
        plant.description.toLowerCase().includes(filter.search.toLowerCase())
        || plant.name.toLowerCase().includes(filter.search.toLowerCase())
      );
      const customerSearch = filter.customers.length <= 0 || (
        filter.customers.some(customerName => customerName === plant.customerName)
      );
      const statusSearch = filter.status.length <= 0 || (
        filter.status.some(status => getStatusByItsTranslationKey(status) === plant.plantStatus)
      );
      const expiredSearch = !filter.expired || (
        filter.expired && plant.isExpired
      );

      return textSearch && customerSearch && statusSearch && expiredSearch;
    }).sort((a, b) => a.name.localeCompare(b.name));

    return result;
  }, [plants, filter]);

  const [customersInfoObject, loadCustomers] = useLoadCustomers();

  useEffect(() => {
    if(customersInfoObject.status === 'idle') {
      loadCustomers();
    }
  }, [customersInfoObject.status, loadCustomers]);

  const customersOptions = customersInfoObject.data.map(item => item.name);

  const { renderFilterWithTags } = useRenderFormFilterWithTags();

  const renderTextFilter = () => {
    return (
      <Box style={{ width: '100%' }}>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextField
              label={t('admin-panel.plant')}
              autoComplete="off"
              style={{ fontWeight: 'bolder', width: '150w' }}
              fullWidth
              {...field}
              InputProps={{ ...field, endAdornment: <SearchIcon fontSize="small" color="disabled" /> }}
            />
          )}
        />
      </Box>
    );
  }

  const renderExpiredCheckboxFilter = () => {
    return (
      <Box style={{ marginTop: '28.5px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <Controller
          name="expired"
          control={control}
          render={({ field }) => (
            <FormCheckbox
              id="expired"
              label={t('filter.expired')}
              {...field}
            />
          )}
        />
      </Box>
    );
  }

  const renderSearchForm = () => {
    const filterContainerStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      gap: '10px',
      width: '100%',
      paddingBottom: '16px'
    }

    return (
      <Box style={filterContainerStyle}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', flex: 1 }}>
          {renderTextFilter()}
          {renderFilterWithTags('status', statusOptions, true)}
          {renderFilterWithTags('customers', customersOptions)}
          {renderExpiredCheckboxFilter()}
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flex: 0, marginTop: '22px' }}>
          <FormButton
            type="submit"
            variant="secondary"
            key="plant-management-search-button"
            size="small" >
            {t('filter.search').toUpperCase()}
          </FormButton>
        </Box>
      </Box>
    );
  }

  const renderCreateNewPlantModal = () =>
    openCreateNewPlantModal && (
      <CreateNewPlantModal
        open={openCreateNewPlantModal}
        onClose={() => {
          handleSendEvent({
            category: 'Users/AdminPanel',
            action: 'User Closed Create New Plant Modal.',
          });
          setOpenCreateNewPlantModal(false);
        }}
        onConfirm={(plant: PlantsCreateRequest) => {
          setOpenCreateNewPlantModal(false);
          createPlant(plant);
        }}
      />
    );

  const renderPlantsTable = () => (
    <PlantManagementTable
      searchInput={filter.search}
      plants={filteredPlants}
      handleConfirmBlockModal={(plant: PlantsGetResponse) => handleConfirmBlockModal(plant)}
    />
  );

  return (
    <>
      <Grid>
        <Loading dataTestId='load-customers-animation' promiseInProgress={customersInfoObject.status === 'loading'} />

        {renderSearchForm()}
        {renderCreateNewPlantModal()}
        {renderBlockPlantDialog()}

        {renderPlantsTable()}
      </Grid>
    </>
  );
};

export default PlantManagementContent;
